var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"header-nav clearfix"},[_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('main')>=0}
            ],attrs:{"to":"/main"}},[_vm._v(" 首页 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('photograph')>=0}
            ],attrs:{"to":"/photograph"}},[_vm._v(" 影像服务 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('lensman')>=0 && _vm.$route.path.indexOf('center')<0}
            ],attrs:{"to":"/lensman"}},[_vm._v(" 摄影师 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('dresser')>=0 && _vm.$route.path.indexOf('center')<0}
            ],attrs:{"to":"/dresser"}},[_vm._v(" 化妆师 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('field')>=0}
            ],attrs:{"to":"/field"}},[_vm._v(" 空间共享 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('clothes')>=0}
            ],attrs:{"to":"/clothes"}},[_vm._v(" 租赁共享 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {'nav-active': _vm.$route.path.indexOf('video')>=0}
            ],attrs:{"to":"/video"}},[_vm._v(" 影像课堂 ")])],1),_c('li',[_c('router-link',{class:[
                'header-nav-item',
                {
                    'nav-active': _vm.$route.path.indexOf('metope')>=0 || _vm.$route.path.indexOf('media')>=0
                }
            ],attrs:{"to":"/metope"}},[_vm._v(" 制作商城 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }