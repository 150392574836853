<template>
    <ul class="header-nav clearfix">
        <li>
            <router-link to="/main"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('main')>=0}
                ]">
                首页
            </router-link>
        </li>
        <li>
            <router-link to="/photograph"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('photograph')>=0}
                ]">
                影像服务
            </router-link>
        </li>
        <li>
            <router-link to="/lensman"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('lensman')>=0 && $route.path.indexOf('center')<0}
                ]">
                摄影师
            </router-link>
        </li>
        <li>
            <router-link to="/dresser"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('dresser')>=0 && $route.path.indexOf('center')<0}
                ]">
                化妆师
            </router-link>
        </li>
        <li>
            <router-link to="/field"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('field')>=0}
                ]">
                空间共享
            </router-link>
        </li>
        <li>
            <router-link to="/clothes"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('clothes')>=0}
                ]">
                租赁共享
            </router-link>
        </li>
        <li>
            <router-link to="/video"
                :class="[
                    'header-nav-item',
                    {'nav-active': $route.path.indexOf('video')>=0}
                ]">
                影像课堂
            </router-link>
        </li>
        <li>
            <router-link to="/metope"
                :class="[
                    'header-nav-item',
                    {
                        'nav-active': $route.path.indexOf('metope')>=0 || $route.path.indexOf('media')>=0
                    }
                ]">
                制作商城
            </router-link>
        </li>
        <!-- <li>
            <router-link to="/equipment"
                :class="[
                    'header-nav-item',
                    {
                        'nav-active': $route.path.indexOf('equipment')>=0 || $route.path.indexOf('cosmetics')>=0
                    }
                ]">
                影像商城
            </router-link>
        </li> -->
    </ul>
</template>

<script>
export default {

}
</script>

<style scoped>
/* 组件容器 */
.header-nav{
    display: flex;
    justify-content: space-between;
}
.header-nav>li{
    flex: 1;
}
.header-nav-item{
    display: block;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-weight: blod;
    color: #303133;
    transition: all .2s linear;
    border-radius: 2px;
}
.header-nav-item:hover{
    background: #8d9afc;
    color: #fff;
}
.nav-active{
    background: #8d9afc;
    color: #fff;
}
</style>
